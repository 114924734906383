export default function useProductTypeList(){

    const columns = [
        { key: 'id', sortable: true },
        { key: 'productType', sortable: true },
        { key: 'description', sortable: true },
        { key: 'Action', sortable: false },
        // { key: 'stock', sortable: true },
    ]
    
    return {
        columns
    }

}